<template>
  <a-modal
    v-if="details"
    :visible="visible"
    title="Detalles de solicitud"
    @cancel="$emit('onCancel')"
    centered
    :bodyStyle="{ color: `${gray_dark_800}` }"
  >
    <div class="item" v-if="status">
      <h6 class="heading-h7 item_subtitle mrg-bottom-0">Estado:</h6>
      <a-tag :color="status.color">{{ status.name }}</a-tag>
    </div>
    <div class="item">
      <h6 class="heading-h7 item_subtitle mrg-bottom-0">Asunto:</h6>
      <p class="body-2 mrg-bottom-0">
        Integración de WhatsApp Business Api con
        {{ transformNameProvider('Securitec') }}
      </p>
    </div>
    <div class="item" v-if="status">
      <h6 class="heading-h7 item_subtitle mrg-bottom-0">Fecha de envio:</h6>
      <p class="body-2 mrg-bottom-0">
        {{ moment(status.created_at).format('LLL') }}
      </p>
    </div>
    <template v-if="details.form">
      <h6 class="heading-h7 title">Datos personales</h6>
      <div class="item" v-for="(item, key) in details.form.user" :key="key">
        <h6 class="heading-h7 item_subtitle mrg-bottom-0">
          {{ transformAttr(key) }}:
        </h6>
        <p v-if="key === 'phone' && item" class="body-2 mrg-bottom-0">
          {{ item.code }} {{ item.number }}
        </p>
        <p v-else class="body-2 mrg-bottom-0">
          {{ item }}
        </p>
      </div>
      <h6 class="heading-h7 title">Datos de la empresa</h6>
      <div class="item" v-for="(item, key) in details.form.company" :key="key">
        <h6 class="heading-h7 item_subtitle mrg-bottom-0">
          {{ transformAttr(key) }}:
        </h6>
        <!-- Para los items del company -->
        <p v-if="!item.nameSpanish && !item.name" class="body-2 mrg-bottom-0">
          {{ item }}
        </p>
        <!-- Para el país que es un objeto -->
        <p v-else class="body-2 mrg-bottom-0">
          {{ item.nameSpanish ? item.nameSpanish : item.name }}
        </p>
      </div>
      <h6
        v-show="
          Object.keys(details.form.facebook).reduce(
            (a, c) => a + (!details.form.facebook[c] ? 0 : 1),
            0
          )
        "
        class="heading-h7 title"
      >
        Datos de Facebook
      </h6>
      <div class="item" v-for="(item, key) in details.form.facebook" :key="key">
        <template v-if="item">
          <h6 class="heading-h7 item_subtitle mrg-bottom-0">
            {{ transformAttr(key) }}:
          </h6>
          <p class="body-2 mrg-bottom-0">
            {{ item || 'N.A.' }}
          </p>
        </template>
      </div>
      <template v-if="details.form.wspbusiness">
        <h6 class="heading-h7 title">Datos de WhatsApp</h6>
        <div class="item">
          <h6 class="heading-h7 item_subtitle mrg-bottom-0">
            Número de WhatsApp:
          </h6>
          <p class="body-2 mrg-bottom-0">
            {{ details.form.wspbusiness.code }}
            {{ details.form.wspbusiness.number }}
          </p>
        </div>
      </template>
    </template>

    <template slot="footer">
      <a-button @click="$emit('onCancel')">Cerrar detalles</a-button>
      <a-button
        v-if="status && status.status === 'rejected'"
        type="primary"
        @click="$emit('onRestart', 'whatsapp')"
        >Volver a enviar</a-button
      >
    </template>
  </a-modal>
</template>

<script>
import moment from 'moment'
import transformMixin from '@/app/shared/mixins/transform'

export default {
  name: 'ModalDetailsRequest',
  props: {
    visible: {
      type: Boolean,
      require: false,
    },
    details: {
      type: Object,
      require: true,
    },
  },
  data: () => ({
    moment,
    gray_dark_800: '#51555A',
  }),
  mixins: [transformMixin],
  computed: {
    /**
     * @returns {Object} {status,color,name}
     */
    status() {
      if (!this.details) return
      if (!Object.keys(this.details).length) return
      const activeStatus = this.details.tracking.find((status) => status.active)
      return this.transformStatus(activeStatus)
    },
  },
  methods: {
    /**
     * @param {Object} status
     * @param {String} status.status
     * @returns {Object} {status,color,name}
     */
    transformStatus(status) {
      switch (status.status) {
        case 'pending':
          return {
            ...status,
            color: 'volcano',
            name: 'Pendiente',
          }
        case 'rejected':
          return {
            ...status,
            color: 'red',
            name: 'Rechazada',
          }
        default:
          return {
            ...status,
            color: 'green',
            name: 'Aprobada',
          }
      }
    },
    transformAttr(attr) {
      switch (attr) {
        case 'names':
          return 'Nombres'
        case 'surnames':
          return 'Apellidos'
        case 'phone':
          return 'Teléfono'
        case 'email':
          return 'Correo'
        case 'chief':
          return 'Cargo'
        case 'companyName':
          return 'Nombre de empresa'
        case 'website':
          return 'Página web'
        case 'country':
          return 'País'
        case 'sector':
          return 'Industria'
        case 'facebookID':
          return 'ID de Facebook'
        case 'facebookURL':
          return 'Página de Facebook'
        case 'lang':
          return 'Idioma'
        case 'address':
          return 'Dirección'
        case 'city':
          return 'Ciudad'
        default:
          return 'Campo'
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.item
  display: flex
  align-items: center
  margin-bottom: 4px
  &_subtitle
    margin-right: 12px
    color: $gray_dark_800
.title
  color: $gray_dark_900
  margin: 16px auto 4px
</style>
