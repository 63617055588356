var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-form',{staticClass:"text--left",attrs:{"form":_vm.form,"layout":"vertical"},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_vm._t("title",function(){return [_c('h4',{staticClass:"semibold-20"},[_vm._v("Preferencias")])]}),_c('p',{staticClass:"regular-14"},[_vm._v(" Configura tu webchat, como su tiempo activo de conversación, mensaje de bienvenida, mensaje de despedida y más. ")]),_c('a-form-item',[_c('h6',{staticClass:"semibold-14 text--left mrg-bottom-4"},[_vm._v("Tiempo de reinicio")]),_c('p',{staticClass:"regular-14 mrg-bottom-12 text--left"},[_vm._v(" Tiempo de persistencia de una conversación. Este tiempo se toma desde el último mensaje enviado por el cliente. Si se excede, se cerrará la conversación y se iniciará una nueva. ")]),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'timeOfLifeAfterLastMessage',
        {
          initialValue: 10,
          rules: [
            {
              required: true,
              message: 'Por favor rellena este campo',
            },
          ],
        },
      ]),expression:"[\n        'timeOfLifeAfterLastMessage',\n        {\n          initialValue: 10,\n          rules: [\n            {\n              required: true,\n              message: 'Por favor rellena este campo',\n            },\n          ],\n        },\n      ]"}],staticClass:"form__select",attrs:{"show-search":"","placeholder":"30 minutos","option-filter-prop":"children","filter-option":_vm.filterOption},on:{"change":_vm.handleChangeTime}},_vm._l((_vm.optionsTime),function(option){return _c('a-select-option',{key:option},[_vm._v(" "+_vm._s(option)+" minutos ")])}),1)],1),_c('a-form-item',[_c('h6',{staticClass:"semibold-14 text--left mrg-bottom-4"},[_vm._v(" Registro de cliente "),_c('a-tooltip',{attrs:{"title":"Si tienes un chatbot activo para este canal, que ya pide datos de tus clientes, esta opción podría ser redundante si está activada.","placement":"rightBottom"}},[_c('a-icon',{attrs:{"type":"info-circle-o"}})],1)],1),_c('div',{staticClass:"display-flex align--center"},[_c('p',{staticClass:"regular-14 mrg-bottom-0 text--left mrg-right-8"},[_vm._v(" Formulario que iniciará una conversación, los datos que se pedirán son: Nombres, documento de identidad, email y número de celular. ")]),_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'isRegistrationRequired',
          { valuePropName: 'checked', initialValue: false },
        ]),expression:"[\n          'isRegistrationRequired',\n          { valuePropName: 'checked', initialValue: false },\n        ]"}],on:{"change":(value) => _vm.hasNewValue({ isRegistrationRequired: value })}})],1)]),_c('a-form-item',[_c('h6',{staticClass:"semibold-14 text--left mrg-bottom-4"},[_vm._v("Marca de agua")]),_c('div',{staticClass:"display-flex align--center"},[_c('p',{staticClass:"regular-14 mrg-bottom-0 text--left mrg-right-8"},[_vm._v(" Desactiva esta opción si deseas que no se muestre la marca de "+_vm._s(_vm.transformNameProvider('Securitec'))+". ")]),_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'isFreeVersion',
          { valuePropName: 'checked', initialValue: true },
        ]),expression:"[\n          'isFreeVersion',\n          { valuePropName: 'checked', initialValue: true },\n        ]"}],on:{"change":_vm.handleChangeWaterMark}})],1)]),_c('a-form-item',{class:{
      'mrg-bottom-0': _vm.hasMessage,
      'mrg-bottom-24': !_vm.hasMessage,
    }},[_c('h6',{staticClass:"semibold-14 text--left mrg-bottom-4"},[_vm._v(" Mensaje inicial "),_c('a-tooltip',{attrs:{"title":"Si tienes un chatbot activo para este canal, este mensaje inicial podría ser redundante con el saludo del chatbot.","placement":"rightBottom"}},[_c('a-icon',{attrs:{"type":"info-circle-o"}})],1)],1),_c('div',{staticClass:"display-flex align--center"},[_c('p',{staticClass:"regular-14 mrg-bottom-0 text--left mrg-right-8"},[_vm._v(" Mensaje de bienvenida a los usuarios cuando inician una conversación. ")]),_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'isWelcomeMessageVisible',
          { valuePropName: 'checked', initialValue: false },
        ]),expression:"[\n          'isWelcomeMessageVisible',\n          { valuePropName: 'checked', initialValue: false },\n        ]"}],on:{"change":_vm.handleChangeHasMessage}})],1)]),(_vm.hasMessage)?_c('a-form-item',[_c('counter-text',{attrs:{"text":_vm.msgWelcome,"maxLength":250}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'welcomeMessage',
          {
            rules: [
              {
                required: true,
                message: 'Por favor completa este campo',
              },
              {
                whitespace: true,
                message: 'No se aceptan espacios vacios',
              },
            ],
          },
        ]),expression:"[\n          'welcomeMessage',\n          {\n            rules: [\n              {\n                required: true,\n                message: 'Por favor completa este campo',\n              },\n              {\n                whitespace: true,\n                message: 'No se aceptan espacios vacios',\n              },\n            ],\n          },\n        ]"}],staticClass:"position-relative",attrs:{"placeholder":"Escribe aquí...","auto-size":{ minRows: 3, maxRows: 4 },"maxLength":250},on:{"input":function($event){return _vm.handleInputMsgWelcome($event.target.value)}}})],1)],1):_vm._e(),_c('a-form-item',[_c('h6',{staticClass:"semibold-14 text--left mrg-bottom-4"},[_vm._v("Mensaje de despedida")]),_c('p',{staticClass:"regular-14 mrg-bottom-12 text--left"},[_vm._v(" Mensaje que se muestra al usuario cuando un agente finaliza un ticket de WebChat o expira el tiempo de reinicio. ")]),_c('counter-text',{attrs:{"text":_vm.msgFinish,"maxLength":250}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'messageToFinish',
          { initialValue: _vm.webchat_demo.messageToFinish },
          {
            rules: [
              {
                required: true,
                message: 'Por favor completa este campo',
              },
              {
                whitespace: true,
                message: 'No se aceptan espacios vacios',
              },
            ],
          },
        ]),expression:"[\n          'messageToFinish',\n          { initialValue: webchat_demo.messageToFinish },\n          {\n            rules: [\n              {\n                required: true,\n                message: 'Por favor completa este campo',\n              },\n              {\n                whitespace: true,\n                message: 'No se aceptan espacios vacios',\n              },\n            ],\n          },\n        ]"}],staticClass:"position-relative",attrs:{"placeholder":"Escribe aquí...","auto-size":{ minRows: 3, maxRows: 4 },"maxLength":250},on:{"input":function($event){return _vm.handleInputMsgFinish($event.target.value)}}})],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }