<template>
  <div class="section-tag">
    <div class="section-tag-card">
      <h6 class="heading-h6 title">
        <a-icon type="tag" class="mrg-right-8" />
        Etiquetas
      </h6>
      <p class="body-2 description" style="margin-bottom: 20px">
        Crea etiquetas, podrán ser asignadas a las conversaciones y así poder
        realizar reportes.
      </p>
      <a-row type="flex" justify="space-between" class="mrg-bottom-16">
        <a-input-search
          v-model="filters.search"
          placeholder="Buscar por título"
          style="width: 256px"
          @change="handleChangeFilters"
        />
        <a-button type="primary" @click="handleShowAddModalFormTag" icon="plus"
          >Crear etiqueta</a-button
        >
      </a-row>
      <!-- TABLA DE ETIQUETAS -->
      <a-row>
        <div>
          <a-table
            class="scrollbar_basic table-tags"
            :columns="columns"
            :data-source="dataOrdered"
            :pagination="pagination"
            :scroll="{ y: 'calc(90.2vh - 19em - 250px)' }"
            size="small"
            :loading="filters.loading"
            @change="handleChangeTable"
          >
            <color-pick slot="color" slot-scope="color" :color="color" />
            <template slot="lines" slot-scope="lines, record">
              <span v-if="record.allLines">Todas las colas</span>
              <span v-else>
                {{ lines.length }}
              </span>
            </template>
            <template slot="action" slot-scope="text, record">
              <a
                :disabled="record.default || record.editable === false"
                @click="handleShowUpdateModalFormTag(record)"
                >Editar</a
              >
              <a-divider type="vertical" />
              <a
                :disabled="record.default || record.editable === false"
                @click="handleDelete(record)"
                >Eliminar</a
              >
            </template>
          </a-table>
        </div>
      </a-row>
      <modal-form-tag
        v-bind="modalFormTag"
        @onClose="handleClosedModalFormTag"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import ColorPick from '@/app/shared/components/pickers/ColorPick.vue'
import transformWordGender from '@/app/shared/mixins/transform'
import ModalFormTag from '@/app/settings/components/molecules/ModalFormTag'
import attemptMixin from '@/app/shared/mixins/attempt'

export default {
  name: 'SectionTag',
  components: {
    ColorPick,
    ModalFormTag,
  },
  data: () => ({
    rows: [],
    colors: [
      '#AFB1B7',
      '#C13131',
      '#5F233F',
      '#C17529',
      '#FFDB01',
      '#FDBD42',
      '#32C38C',
      '#1ABEC7',
      '#73B9D7',
      '#FB3611',
      '#5F4C8C',
      '#001A3D',
      '#96B2A6',
    ], // Lista de colores predeterminados
    columns: [
      {
        title: 'Nº',
        key: 'order',
        dataIndex: 'order',
        width: '7%',
      },
      {
        title: 'Nombre de etiqueta',
        key: 'title',
        dataIndex: 'title',
        width: '30%',
        scopedSlots: { customRender: 'title' },
      },
      {
        title: 'Color',
        key: 'color',
        dataIndex: 'color',
        scopedSlots: { customRender: 'color' },
      },
      {
        title: 'Colas asignadas',
        key: 'lines',
        dataIndex: 'lines',
        scopedSlots: { customRender: 'lines' },
        width: '30%',
      },
      {
        title: 'Acción',
        key: 'action',
        dataIndex: 'action',
        width: '20%',
        scopedSlots: { customRender: 'action' },
      },
    ],
    filters: {
      search: '',
      loading: false,
      page: 1,
    },
    modalFormTag: {
      visible: false,
      title: '',
      dataSource: {},
      okText: '',
      onOk: () => {},
    },
  }),
  props: {
    tags: {
      type: Array,
      required: true,
    },
  },
  watch: {
    tags() {
      this.load()
    },
  },
  mixins: [transformWordGender, attemptMixin],
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'normal' })
    this.formEdit = this.$form.createForm(this, { name: 'normal' })
  },
  mounted() {
    this.$nextTick(() => {
      this.intervalAttempt(() => {
        if (!this.profile) throw 'No profile'
        this.loadInitialData()
      })
    })
  },
  computed: {
    ...mapGetters(['profile', 'paginate_tags', 'company_tags']),

    /**
     * @returns {Object} - paginación para la tabla
     */
    pagination() {
      return {
        hideOnSinglePage: true,
        defaultPageSize: 5,
        size: 'default',
        total: this.paginate_tags.total || 0,
        current: this.paginate_tags.page,
      }
    },
    dataOrdered() {
      if (!this.company_tags) return []

      let startOrder =
        1 + (this.paginate_tags.page - 1) * this.pagination.defaultPageSize
      return this.company_tags.map((item) => {
        item.order = startOrder++
        return item
      })
    },
  },
  methods: {
    ...mapActions([
      'addCompanyTag',
      'getCompanyTags',
      'updateCompanyTag',
      'deleteCompanyTag',
    ]),
    /** Carga las filas para la tabla */
    loadInitialData() {
      this.handleChangeFilters()
    },
    /**
     * Escucha el cambio de filtros
     */
    async handleChangeFilters() {
      this.filters.loading = true
      await this.getCompanyTags(this.filters)
      this.filters.loading = false
    },
    /**
     * Escucha el cambio de paginacion de la tabla
     * @param {Object} pagination
     * @param {Number} pagination.current página actual
     */
    handleChangeTable(pagination) {
      this.filters.page = pagination.current
      this.handleChangeFilters()
    },
    /**
     * Agrega los valores de la etiqueta
     * @param {Object} values
     * @param {String} values.title
     * @param {String} values.color
     * @param {String[]} values.linesIds
     */
    async handleAddTag(values) {
      const response = await this.addCompanyTag(values)
      if (response.success) {
        this.$message.success({
          content: `La etiqueta se creó correctamente`,
          key: 'updatable',
          duration: 2,
        })
        this.handleClosedModalFormTag()
      }
    },
    /**
     * Actualiza los valores de la etiqueta
     * @param {Object} values
     * @param {String} values.title
     * @param {String} values.color
     * @param {String[]} values.linesIds
     */
    async handleUpdateTag(values) {
      const response = await this.updateCompanyTag(values)
      if (response.success) {
        this.$message.success({
          content: `La etiqueta se actualizó correctamente`,
          key: 'updatable',
          duration: 2,
        })
        this.handleClosedModalFormTag()
      }
    },
    /**
     * Muestra el modal para crear una etiqueta
     */
    handleShowAddModalFormTag() {
      this.modalFormTag.title = 'Crear una etiqueta'
      this.modalFormTag.okText = 'Crear etiqueta'
      this.modalFormTag.onOk = this.handleAddTag
      this.modalFormTag.visible = true
    },
    /**
     * Muestra el modal para editar una etiqueta
     * @param {*} record
     */
    handleShowUpdateModalFormTag(record) {
      this.modalFormTag.dataSource = record
      this.modalFormTag.title = 'Edita una etiqueta'
      this.modalFormTag.okText = 'Editar etiqueta'
      this.modalFormTag.onOk = this.handleUpdateTag
      this.modalFormTag.visible = true
    },
    /**
     * Cierra el modal de etiquetas
     */
    handleClosedModalFormTag() {
      this.modalFormTag.title = ''
      this.modalFormTag.okText = ''
      this.modalFormTag.dataSource = {}
      this.modalFormTag.visible = false
      this.modalFormTag.onOk = () => {}
    },
    /** Quita a una fila de la tabla
     * @param {Object} record
     * @param {Boolean} record.default
     * @param {String} record._id
     * @param {String} record.title
     */
    async handleDelete(record) {
      if (record.default) return
      const self = this
      const wordSure = this.transformWordGender('seguro', this.profile.gender)
      // modal de confirmación
      this.$confirm({
        title: `¿Estás ${wordSure} de eliminar la etiqueta "${record.title}”?`,
        content:
          'Recuerda que la etiqueta se mantendrá en los tickets que lo tienen asignado',
        async onOk() {
          const response = await self.deleteCompanyTag(record._id)
          if (response.success) {
            self.$message.success({
              content:
                response.details || `La etiqueta se eliminó correctamente`,
              key: 'updatable',
              duration: 2,
            })
          } else {
            self.$message.error({
              content: response.details || 'Ocurrió un error 😥',
              key: 'updatable',
              duration: 2,
            })
          }
        },
        okText: 'Eliminar',
        okType: 'danger',
        cancelText: 'Cancelar',
        centered: true,
      })
    },
    clearFields() {
      this.form.setFieldsValue({
        tagName: '',
        tagColor: '',
      })
    },
    setDefaultColor() {
      this.form.setFieldsValue({
        tagColor: '#AFB1B7',
      })
    },
  },
}
</script>

<style lang="sass" scoped>
// $max-table: 537px

.section-tag
  padding: 12px 20px
  border: 1px solid #D9D9D9
  border-radius: 8px
  &-card
    .title
      text-align: left
      color: $gray_dark_800
    .description
      text-align: left
      color: $gray_light_900
  // TODO [DEPRECATED]
  // .ant-row
  //   // max-width: $max-table
  //   // margin: 0 auto
  //   .inline
  //     display: flex
  //     .ant-select
  //       margin: 0 8px
  //       min-width: 84px
  //       width: min-content
  //     input[type="text"]
        // max-width: 390px
</style>

<style lang="sass">
.section-tag
  .ant-row
    // TODO: [DEPRECATED]
    // .ant-select
    //   .ant-select-selection__rendered
    //     height: min-content
    //     display: flex
    //     align-items: center
    .scrollbar_basic
      .ant-table-body
        &::-webkit-scrollbar
          width: 10px
        &::-webkit-scrollbar-track
          background: transparent
          border-left: solid 5px transparent
        &::-webkit-scrollbar-thumb
          border-radius: 8px
          box-shadow: inset 0 0 10px 10px $gray_dark_100
          border: solid 3px transparent
        &::-webkit-scrollbar-thumb:hover
          box-shadow: inset 0 0 10px 10px $gray_dark_100
    table
      tbody
        td
          padding: 8px !important
          &:nth-child(3n) // En este caso, en la tercera columna se encuentra en select de colores
            // padding: 0px !important
          // input
          //   width: 95%
.table-tags
  .ant-spin-nested-loading
    .ant-spin-container
      .ant-table
        .ant-table-content
          .ant-table-scroll
            .ant-table-header
              overflow-y: auto !important
            .ant-table-body
              overflow-y: auto !important
</style>
