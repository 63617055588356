<template>
  <div class="banner">
    <div class="banner_header">
      <p class="heading-h8 mrg-bottom-0 header_title">
        Información de número conectado
      </p>
      <span>
        <!--para el proveedor de securitec-->
        <template v-if="channel.api_version !== 'twilio_v3'">
          <a @click="$emit('onOpenDetails')">
            <a-icon type="edit" />
            Ver más detalles y solicitar editar datos</a
          >
          <a-divider type="vertical" class="mrg-right-8 mrg-left-8" />
        </template>
        <template v-else>
          <a @click="$emit('onOpenDetails')">
            <a-icon type="eye" class="mrg-right-8" />
            Ver más detalles</a
          >
          <a-divider type="vertical" class="mrg-right-8 mrg-left-8" />
        </template>
        <template>
          <a v-if="!hasDown" @click="$emit('onOpenUnsubscribe')" class="red_6">
            <a-icon type="delete" class="mrg-right-8" />
            <span>Eliminar WhatsApp</span>
          </a>
          <a-tooltip v-else>
            <template slot="title">
              Ya hay una solicitud pendiente. Nos contactaremos contigo.
            </template>
            <a class="gray_6">
              <a-icon type="delete" class="mrg-right-8" />
              <span>Eliminar WhatsApp</span>
            </a>
          </a-tooltip>
        </template>
      </span>
    </div>
    <div class="banner_content">
      <div class="col">
        <a-badge
          dot
          :status="status"
          :numberStyle="{
            height: '12px',
            width: '12px',
            right: '6px',
            top: '6px',
          }"
          ><a-avatar
            :size="52"
            :src="imgSupplier(channel.supplier, channel.request)"
            :style="{ border: `1px solid ${gray_5}` }"
        /></a-badge>
        <span class="text">
          <p class="heading-h7 mrg-bottom-0" v-if="channel.credentials">
            {{ channel.credentials.wspbusiness }}
          </p>
          <a-row type="flex" class="mrg-top-8">
            <p
              class="body-1 mrg-bottom-0 description"
              :class="{
                info_i2: !modalUpdateAlias.alias,
              }"
            >
              Alias: {{ modalUpdateAlias.alias || 'Sin alias' }}
            </p>
            <a
              @click="modalUpdateAlias.visible = true"
              class="mrg-left-12 namm"
            >
              <a-icon type="edit" />
            </a>
            <modal-update-alias
              :visible="modalUpdateAlias.visible"
              :channelCompanyId="channel._id"
              :defaultAlias="channel.alias || null"
              @onCancel="modalUpdateAlias.visible = false"
              @onUpdate="(alias) => (modalUpdateAlias.alias = alias)"
            />
          </a-row>
        </span>
      </div>
      <div class="items">
        <div class="item">
          <p class="heading-h8 mrg-bottom-0 item_title">Proveedor</p>
          <p class="body-1 mrg-bottom-0">
            {{ transformNameProvider(channel.supplier) }}
          </p>
        </div>
        <template v-if="channel.form.company && channel.form.company.country">
          <a-divider type="vertical" class="item_divider" />
          <div class="item">
            <p class="heading-h8 mrg-bottom-0 item_title">País</p>
            <p class="body-1 mrg-bottom-0">
              {{ channel.form.company.country }}
            </p>
          </div>
        </template>
        <a-divider type="vertical" class="item_divider" />
        <div class="item">
          <p class="heading-h8 mrg-bottom-0 item_title">Fecha de activación</p>
          <p class="body-1 mrg-bottom-0">
            {{ dateWasApproved }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SecuritecLogo from '@/app/channels/assets/securitec-logo-52x52.png'
import TwilioLogo from '@/app/channels/assets/twilio-logo-52x52.png'
import TedexisLogo from '@/app/channels/assets/tedexis-logo-dark-52x52.png'
import ModalUpdateAlias from '@/app/channels/components/whatsapp/modals/ModalUpdateAlias'
import moment from 'moment'
import vari from '@/app/shared/utils/variables'
import transformMixin from '@/app/shared/mixins/transform'

export default {
  name: 'BannerProvider',
  props: {
    channel: {
      type: Object,
      required: false,
    },
  },
  components: {
    ModalUpdateAlias,
  },
  data: () => ({
    // hasDown: false,
    gray_5: '#D9D9D9',
    gray_dark_400: '#A8AAAD',
    modalUpdateAlias: {
      visible: false,
      alias: null,
    },
  }),
  mixins: [transformMixin],
  mounted() {
    this.modalUpdateAlias.alias =
      this.channel && this.channel.alias ? this.channel.alias : null
  },
  computed: {
    ...mapGetters(['company']),
    /**
     * @returns {String}
     */
    status() {
      return this.channel.status && this.channel.status === 'approved'
        ? 'success'
        : 'error'
    },
    /**
     * Obtiene el ultimo estado de aprobado
     * @returns {Date}
     */
    dateWasApproved() {
      return moment(this.channel.approvedAt).format('LLL')
    },
    /**
     * @return {Boolean}
     */
    hasDown() {
      return this.channel.status === 'pre-unsuscribing'
    },
  },
  methods: {
    /**
     * Retorna el icono de  cada proveedor
     * @param {String} supplier nombre de proveedor
     * @param {Boolean} request si es proveedor
     * @returns {Component} Logo
     */
    imgSupplier(supplier, request) {
      // si es default
      if (request) supplier = 'securitec'
      if (vari.APP_NAME === 'Tedexis') supplier = 'tedexis'

      switch (supplier) {
        case 'Twilio':
          return TwilioLogo
        case 'securitec':
          return SecuritecLogo
        case 'tedexis':
          return TedexisLogo
        default:
          return SecuritecLogo
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.red_6
  color: $red_6
.gray_6
  color: $gray_6

.banner
  padding: 16px
  border: 1px solid $gray_5
  border-radius: 4px
  &_header
    display: flex
    align-items: center
    justify-content: space-between
    .header_title
      color: $gray_dark_800
  &_content
    padding-top: 4px
    display: flex
    justify-content: space-between
    align-items: flex-end
    .col
      display: flex
      align-items: center
      .text
        margin-left: 12px
        text-align: left
        margin-right: 120px
        .description
          color: $gray_dark_600
    .items
      display: flex
      align-items: center
      .item
        text-align: left
        color: $gray_dark_800
        &_title
          color: $gray_dark_900
        &_divider
          margin-right: 12px
          margin-left: 12px
</style>
