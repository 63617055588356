<template>
  <a-form :form="form" layout="vertical" @submit.prevent="handleSubmit">
    <custom-section-scroll
      idMaster="form-request-sec"
      :contentStyles="{ padding: '20px 20px 0px' }"
      :masterStyles="{
        height: '490px',
        border: `1px solid ${gray_dark_300}`,
        borderRadius: '8px',
      }"
    >
      <h6 class="heading-h7">Datos de la empresa</h6>
      <a-form-item
        label="Nombre de empresa"
        :validate-status="verifiedError('companyName') ? 'error' : ''"
        :help="verifiedError('companyName') || ''"
      >
        <a-input
          v-decorator="[
            'companyName',
            {
              rules: [
                {
                  required: true,
                  message: 'Por favor completa este campo',
                },
              ],
            },
          ]"
          placeholder="Escribe aquí..."
        />
      </a-form-item>
      <a-row :gutter="[12]">
        <a-col :span="12">
          <a-form-item
            label="Industria"
            :validate-status="verifiedError('sector') ? 'error' : ''"
            :help="verifiedError('sector') || ''"
          >
            <a-select
              show-search
              v-decorator="[
                'sector',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Por favor completa este campo',
                    },
                  ],
                },
              ]"
              placeholder="Selecciona"
              option-filter-prop="children"
            >
              <a-select-option v-for="industry in industries" :key="industry">
                {{ industry }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item
            label="Idioma"
            :validate-status="verifiedError('lang') ? 'error' : ''"
            :help="verifiedError('lang') || ''"
          >
            <a-select
              v-decorator="[
                'lang',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Por favor completa este campo',
                    },
                  ],
                },
              ]"
              placeholder="Selecciona"
            >
              <a-select-option v-for="language in languages" :key="language">
                {{ language }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="[12]">
        <a-col :span="12">
          <a-form-item
            label="País"
            :validate-status="verifiedError('country') ? 'error' : ''"
            :help="verifiedError('country') || ''"
          >
            <a-select
              show-search
              v-decorator="[
                'country',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Por favor completa este campo',
                    },
                  ],
                },
              ]"
              option-filter-prop="children"
              placeholder="Selecciona"
            >
              <a-select-option v-for="country in countries" :key="country._id">
                {{ country.nameSpanish }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item
            label="Ciudad"
            :validate-status="verifiedError('city') ? 'error' : ''"
            :help="verifiedError('city') || ''"
          >
            <a-input
              v-decorator="[
                'city',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Por favor completa este campo',
                    },
                  ],
                },
              ]"
              placeholder="Escribe aquí..."
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-form-item
        label="Dirección"
        :validate-status="verifiedError('address') ? 'error' : ''"
        :help="verifiedError('address') || ''"
      >
        <a-input
          v-decorator="[
            'address',
            {
              rules: [
                {
                  required: true,
                  message: 'Por favor completa este campo',
                },
              ],
            },
          ]"
          placeholder="Escribe aquí..."
        />
      </a-form-item>
      <a-form-item
        label="Página web"
        :validate-status="verifiedError('website') ? 'error' : ''"
        :help="verifiedError('website') || ''"
      >
        <a-auto-complete
          v-decorator="[
            'website',
            {
              rules: [
                {
                  required: true,
                  message: 'Por favor completa este campo',
                },
                {
                  whitespace: true,
                  message: 'No se aceptan espacios vacios',
                },
              ],
            },
          ]"
          placeholder="Escribe aquí..."
          @change="handleWebsiteChange"
        >
          <template slot="dataSource">
            <a-select-option
              v-for="website in autoCompleteResult"
              :key="website"
            >
              {{ website }}
            </a-select-option>
          </template>
          <a-input />
        </a-auto-complete>
      </a-form-item>
      <div class="title-container">
        <h6 class="heading-h7 mrg-bottom-0">Datos de Facebook</h6>
        <a-tooltip>
          <template slot="title">
            Si no cuentas con estos datos, igual {{ app }} responderá para
            ayudarte
          </template>
          <a-icon type="question-circle" class="title-container__icon" />
        </a-tooltip>
      </div>
      <a-form-item label="Página de Facebook">
        <a-input
          v-decorator="[
            'facebookURL',
            {
              rules: [
                {
                  required: false,
                  message: 'Por favor completa este campo',
                },
              ],
            },
          ]"
          placeholder="Escribe aquí..."
        />
      </a-form-item>
    </custom-section-scroll>
    <div class="form_buttons">
      <a-button @click="$emit('onCancel')" class="form_buttons__outlined"
        >Volver</a-button
      >
      <a-button
        html-type="submit"
        type="primary"
        :disabled="hasErrors(form.getFieldsError())"
        :loading="loading"
        >Enviar solicitud a {{ transformNameProvider('Securitec') }}</a-button
      >
    </div>
  </a-form>
</template>

<script>
import CustomSectionScroll from '@/app/shared/components/sections/CustomSectionScroll'
import transformMixin from '@/app/shared/mixins/transform'

export default {
  name: 'FormRequestSec',
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    industries: {
      type: Array,
      required: false,
      default: () => ['retail', 'tech'],
    },
    languages: {
      type: Array,
      required: false,
      default: () => ['spanish', 'english'],
    },
    countries: {
      type: Array,
      required: false,
      default: () => [{ _id: '1', nameSpanish: 'Perú', dialCode: '+51' }],
    },
    company: {
      type: Object,
      required: true,
    },
    profile: {
      type: Object,
      required: true,
    },
  },
  components: {
    CustomSectionScroll,
  },
  data: () => ({
    app: process.env.VUE_APP_NAME,
    gray_dark_300: '#BDBFC1',
    autoCompleteResult: [], // para website
  }),
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'form-request-sec' })
  },
  mounted() {
    this.$nextTick(() => {
      this.setFieldsValue()
      // To disabled submit button at the beginning.
      this.form.validateFields()
    })
  },
  mixins: [transformMixin],
  methods: {
    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          const newValues = {
            company: {
              companyName: values.companyName,
              country: this.countrySelected(values.country)
                ? this.countrySelected(values.country)
                : this.company.country,
              city: values.city || '',
              sector: values.sector,
              lang: values.lang,
              address: values.address || '',
              website: values.website || '',
            },
            user: {},
            facebook: {
              facebookURL: values.facebookURL,
            },
          }
          this.$emit('onSubmit', newValues)
        }
      })
    },
    setFieldsValue() {
      this.form.setFieldsValue({
        companyName: this.company.name,
        sector: this.company.industry,
        country: this.company.country.name,
        address: this.company.address || '',
        website: this.company.webpage || '',
      })
    },
    hasErrors(fieldsError) {
      return Object.keys(fieldsError).some((field) => fieldsError[field])
    },
    // Only show error after a field is touched.
    verifiedError(attr) {
      const { getFieldError, isFieldTouched } = this.form
      return isFieldTouched(`${attr}`) && getFieldError(`${attr}`)
    },
    countrySelected(_id) {
      const countryFound = this.countries.find((country) => country._id === _id)
      if (countryFound !== undefined) {
        return countryFound
      } else return null
    },
    handleWebsiteChange(value) {
      let autoCompleteResult
      if (!value) {
        autoCompleteResult = []
      } else {
        autoCompleteResult = ['.com', '.org', '.net'].map(
          (domain) => `${value}${domain}`
        )
      }
      this.autoCompleteResult = autoCompleteResult
    },
  },
}
</script>

<style lang="sass" scoped>
.form_buttons
  margin-top: 16px
  display: flex
  align-items: flex-end
  justify-content: flex-end
  &__outlined
    margin-right: 8px
.title-container
  display: flex
  align-items: center
  margin-bottom: 8px
  &__icon
    margin-left: 8px
    font-size: 16px
</style>
