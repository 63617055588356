import { render, staticRenderFns } from "./ModalDetailsRequest.vue?vue&type=template&id=02ca4634&scoped=true&"
import script from "./ModalDetailsRequest.vue?vue&type=script&lang=js&"
export * from "./ModalDetailsRequest.vue?vue&type=script&lang=js&"
import style0 from "./ModalDetailsRequest.vue?vue&type=style&index=0&id=02ca4634&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02ca4634",
  null
  
)

export default component.exports