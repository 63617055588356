import { render, staticRenderFns } from "./SectionMarket.vue?vue&type=template&id=256cd11f&scoped=true&"
import script from "./SectionMarket.vue?vue&type=script&lang=js&"
export * from "./SectionMarket.vue?vue&type=script&lang=js&"
import style0 from "./SectionMarket.vue?vue&type=style&index=0&id=256cd11f&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "256cd11f",
  null
  
)

export default component.exports