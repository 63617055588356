<template>
  <a-popover
    title="Selecciona etiquetas"
    :placement="placement"
    trigger="click"
  >
    <template slot="content">
      <span v-if="showEmpty" class="popover--empty"
        >Aún no hay etiquetas asignadas.</span
      >
      <list-checkboxes
        v-else
        :data-source="dataSource"
        @onChange="(checked, key) => $emit('onCheck', checked, key)"
      >
        <template v-slot:description="slotsProps">
          <span
            >{{ slotsProps.checked }}/{{ slotsProps.total }} Actualmente
            seleccionados
          </span>
        </template>
        <template v-slot:title="slotsProps">
          <a-tag :color="slotsProps.data.color">{{
            slotsProps.data.title
          }}</a-tag>
          <span v-if="slotsProps.data.unassigned" class="color--disabled"
            >(Desasignado)</span
          >
        </template>
      </list-checkboxes>
    </template>
    <slot>
      <a-button> Escoger</a-button>
    </slot>
  </a-popover>
</template>

<script>
import ListCheckboxes from '@/app/shared/components/molecules/ListCheckboxes'
import { mapGetters } from 'vuex'

export default {
  name: 'PopoverTagsCheckboxes',
  props: {
    dataSource: {
      type: Array,
      required: true,
    },
    placement: {
      type: String,
      required: false,
      default: 'bottomRight',
    },
  },
  components: {
    ListCheckboxes,
  },
  computed: {
    ...mapGetters(['tags']),

    /**
     * @returns {Boolean} - muestra o no una descripcion cuando no hay data
     */
    showEmpty() {
      return !this.dataSource || !this.dataSource.length
    },
  },
}
</script>

<style lang="sass" scoped>
.popover--empty
  color: $gray_6
</style>
