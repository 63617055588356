/** Archivos de variables globales */
/** Llamar a este fichero como "vari" en las importaciones */
import { version } from '~/../../package'

const UHR = process.env.VUE_APP_HOST // URL del Host Restfull
const UHS = process.env.VUE_APP_SOCKET_IO // URL del Host de Sockets
const UHM = process.env.VUE_APP_HOST_MASSIVE // URL del Host Masivo

const WEB = process.env.VUE_APP_WEB // URL de la web
const WEB_APP = process.env.VUE_APP_WEB_APP // URL de la plataforma
const WEB_DOC = process.env.VUE_APP_WEB_DOC // URL de la documentacion
const WEB_PRICING = process.env.VUE_APP_WEB_PRICING // URL del pricing web
const WEB_SUPPORT = process.env.VUE_APP_WEB_SUPPORT // URL de la web soporte
const WEB_POLICY = process.env.VUE_APP_WEB_POLICY // URL de los terminos y condiciones
const WEB_EMAIL_SUPPORT = process.env.VUE_APP_OFICIAL_EMAIL // email para soporte

const WEB_PUSH_PUBLIC_VAPID_KEY = process.env.VUE_APP_WEB_PUSH_PUBLIC_VAPID_KEY

const APP_VERSION = version
const APP_NAME = process.env.VUE_APP_NAME // Nombre de la app

export default {
  UHR,
  UHS,
  UHM,
  WEB,
  WEB_APP,
  WEB_DOC,
  WEB_PRICING,
  WEB_SUPPORT,
  WEB_POLICY,
  WEB_PUSH_PUBLIC_VAPID_KEY,
  APP_VERSION,
  APP_NAME,
  WEB_EMAIL_SUPPORT,
}
