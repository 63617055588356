<template>
  <a-modal
    :visible="visible"
    :title="title"
    :body-style="bodyStyles"
    width="54em"
    @cancel="$emit('onCancel')"
    centered
  >
    <a-tabs
      :default-active-key="defaultActiveKey"
      size="small"
      class="modal__tabs"
      :tabBarGutter="12"
      :tabBarStyle="{
        margin: '20px 20px 0px 20px',
      }"
      v-model="tabSelected"
    >
      <a-tab-pane
        v-if="hasBroadcast"
        key="profile"
        tab="Perfil de WhatsApp"
        force-render
      >
        <custom-section-scroll
          :master-styles="{ height: '32em' }"
          :content-styles="{ padding: '24px 4em' }"
        >
          <a-row type="flex" justify="space-between">
            <form-profile-wba
              ref="form_profile_wba"
              :broadcast="broadcast"
              :request="request"
              :supplier="supplier"
              class="tab__form"
              @onChangeProfile="handleChangeMobileContent"
            />
            <mobile-wsp
              layout="profile"
              type="small"
              :data-source-profile="mobileContent"
            />
          </a-row>
        </custom-section-scroll>
      </a-tab-pane>
      <a-tab-pane
        v-if="hasIntegration"
        key="integration"
        tab="Datos de integración"
      >
        <custom-section-scroll
          :master-styles="{ height: '32em' }"
          :content-styles="{ padding: '24px 12em' }"
        >
          <h6 class="heading-h7">Webhooks de integración</h6>
          <a-form-item
            v-for="webhook in integration.webhooks"
            :key="webhook._id"
            :label="webhook.name"
          >
            <a-input
              class="cursor-default"
              v-model="webhook.uri"
              placeholder="Escribe aquí..."
              readOnly
            >
              <a-icon
                slot="suffix"
                type="copy"
                v-clipboard:copy="webhook.uri"
                v-clipboard:success="onCopyWebhook"
                v-clipboard:error="onError"
                class="icon_copy cursor-pointer"
              />
            </a-input>
          </a-form-item>

          <h6 class="heading-h7">Datos de contacto</h6>
          <p class="body-2">
            Estos datos son usados por
            {{ transformNameProvider(supplier) }} para poder comunicarse
            contigo.
          </p>
          <a-form-item label="Nombres y apellidos" v-if="integration.form.user">
            <a-input
              placeholder="Escribe aquí..."
              :defaultValue="`${integration.form.user.names} ${integration.form.user.surnames}`"
              :disabled="true"
            />
          </a-form-item>
          <a-form-item v-if="integration.form.user.phone" label="Teléfono">
            <a-row :gutter="8" type="flex">
              <!--El orden de las columnas debe estar al reves para que se pueda reconocer el error de telefono-->
              <a-col :span="16" :order="2">
                <a-input
                  v-model="integration.form.user.phone.number"
                  :maxLenght="20"
                  placeholder="Escribe aquí..."
                  :disabled="true"
                />
              </a-col>
              <a-col :span="8" :order="1">
                <a-select
                  show-search
                  option-filter-prop="children"
                  placeholder="País"
                  :disabled="true"
                  :default-value="integration.form.user.phone.code"
                  class="tab__select"
                >
                  <a-select-option
                    v-for="country in countriesNotRepeatByName"
                    :key="country._id"
                    :title="country.nameSpanish"
                  >
                    {{ country.dialCode }}
                    {{ country.nameSpanish }}
                  </a-select-option>
                </a-select>
              </a-col>
            </a-row>
          </a-form-item>
          <a-form-item
            label="Correo electrónico"
            v-if="integration.form.user.email"
          >
            <a-input
              :default-value="integration.form.user.email"
              placeholder="Escribe aquí..."
              :disabled="true"
            />
          </a-form-item>
        </custom-section-scroll>
      </a-tab-pane>
      <a-tab-pane v-if="hasFacebook" key="facebook" tab="Datos de Facebook">
        <custom-section-scroll
          :master-styles="{ height: '32em' }"
          :content-styles="{ padding: '24px 12em' }"
        >
          <a-form-item label="ID de Facebook">
            <a-input
              v-model="facebook.facebookID"
              placeholder="Escribe aquí..."
              readOnly
              class="cursor-default"
            >
              <a-icon
                slot="suffix"
                type="copy"
                v-clipboard:copy="facebook.facebookID"
                v-clipboard:success="onCopyFacebookID"
                v-clipboard:error="onError"
                class="icon_copy cursor-pointer"
              />
            </a-input>
          </a-form-item>
        </custom-section-scroll>
      </a-tab-pane>
    </a-tabs>
    <basic-footer
      slot="footer"
      @onOk="handleSubmit"
      okText="Enviar solicitud para editar datos"
      cancelText="Cancelar"
      :okLoading="loadingButton"
      @onCancel="handleCancel"
      :okDisabled="
        !broadcast.editable ||
        tabSelected === 'integration' ||
        tabSelected === 'facebook'
      "
    />
  </a-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import vari from '@/app/shared/utils/variables'
import BasicFooter from '@/app/shared/components/molecules/BasicFooter'
import CustomSectionScroll from '@/app/shared/components/sections/CustomSectionScroll'
import FormProfileWba from '@/app/channels/components/whatsapp/forms/FormProfileWba'
import MobileWsp from '@/app/strategies/components/MobileWsp'
import SecuritecLogo from '@/app/channels/assets/securitec-full-logo.png'
import TwilioLogo from '@/app/channels/assets/twilio-full-logo.png'
import TedexisLogo from '@/app/channels/assets/tedexis-full-logo-dark.png'
import transformMixin from '@/app/shared/mixins/transform'

export default {
  name: 'ModalDetailsWba',
  props: {
    visible: {
      type: Boolean,
      required: false,
    },
    numberWba: {
      type: String,
      required: true,
    },
    broadcast: {
      type: Object,
      required: true,
    },
    integration: {
      type: Object,
      required: true,
    },
    facebook: {
      type: Object,
      required: true,
    },
    request: {
      type: Boolean,
      required: true,
    },
    supplier: {
      type: String,
      required: true,
    },
    channelCompanyId: {
      type: String,
      required: true,
    },
  },
  components: {
    BasicFooter,
    CustomSectionScroll,
    FormProfileWba,
    MobileWsp,
  },
  data: () => ({
    bodyStyles: {
      padding: '0px',
    },
    mobileContent: {},
    loadingButton: false,
    tabSelected: '',
  }),
  mounted() {
    this.mobileContent = { ...this.broadcast }
    this.mobileContent.name = this.numberWba
    this.mobileContent.avatar =
      this.broadcast && this.broadcast.avatar
        ? this.broadcast.avatar
        : this.imgSupplier(this.supplier, this.request)

    this.tabSelected = this.defaultActiveKey
  },
  mixins: [transformMixin],
  watch: {
    defaultActiveKey(newVal) {
      this.tabSelected = newVal
    },
  },
  computed: {
    ...mapGetters(['countriesNotRepeatByName']),

    hasBroadcast() {
      return this.broadcast && !!Object.keys(this.broadcast).length
    },
    hasIntegration() {
      return this.integration && !!Object.keys(this.integration).length
    },
    hasFacebook() {
      return (
        this.facebook &&
        !!Object.keys(this.facebook).length &&
        this.facebook.facebookID
      )
    },
    defaultActiveKey() {
      if (this.hasBroadcast) return 'profile'
      else if (this.hasIntegration) return 'integration'
      else if (this.hasFacebook) return 'facebook'
      else return ''
    },
    title() {
      return `Detalles de solicitud "${this.numberWba}"`
    },
  },
  methods: {
    ...mapActions(['sendRequestToEdit']),

    handleChangeMobileContent({ property, value }) {
      this.mobileContent[property] = value
    },
    /**
     * Retorna el icono de  cada proveedor
     * @param {String} supplier nombre de proveedor
     * @param {Boolean} request si es proveedor
     * @return {Component} image
     */
    imgSupplier(supplier, request) {
      // si es default
      if (request) supplier = 'securitec'
      if (vari.APP_NAME === 'Tedexis') supplier = 'tedexis'

      switch (supplier) {
        case 'Twilio':
          return TwilioLogo
        case 'securitec':
          return SecuritecLogo
        case 'tedexis':
          return TedexisLogo
        default:
          return SecuritecLogo
      }
    },
    handleSubmit() {
      const sectionProfile = this.$refs.form_profile_wba
      sectionProfile.form.validateFieldsAndScroll(async (err, values) => {
        if (!err) {
          const formattedValues = {
            ...values,
            channelCompanyId: this.channelCompanyId,
          }

          if (sectionProfile.fileImage) {
            formattedValues['whatsapp_avatar'] = sectionProfile.fileImage
          }
          this.handleOpenConfirmation(formattedValues)
        }
      })
    },
    handleOpenConfirmation(newValues) {
      this.loadingButton = true
      let self = this
      this.$confirm({
        title: '¿Estás segur@ de enviar estos cambios?',
        content:
          'Recuerda que tu solicitud será atendida en un promedio de 3 o 5 días.',
        okText: 'Enviar',
        cancelText: 'Cancelar',
        centered: true,
        async onOk() {
          const response = await self.sendRequestToEdit(newValues)
          if (response.success) {
            self.$message.success('Se envió correctamente la solicitud 🤞')
            self.handleCancel()
          } else {
            self.$message.error('Ocurrió un error al mandar tu solicitud 😪')
          }
          self.loadingButton = false
        },
      })
    },
    handleCancel() {
      this.$emit('onCancel')
    },
    onCopyWebhook() {
      this.$message.success('La webhook de error se copio')
    },
    onCopyFacebookID() {
      this.$message.success('El facebook id se copio')
    },
    onError() {
      this.$message.error('Fallo')
    },
  },
}
</script>

<style lang="sass" scoped>
.tab__form
  width: 358px
.tab__select
  width: 100%
</style>
