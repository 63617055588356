<template>
  <div class="templates-list">
    <a-table
      class="table-supervision table-scroll"
      :data-source="dataTemplates.data"
      :pagination="false"
      :loading="load"
      rowKey="_id"
      size="middle"
      ref="table"
      @scroll="handleBottomReached"
      :scroll="{ x: '90vw', y: 'calc(100vh - 450px)' }"
      :locale="{ emptyText: 'No se han encontrado plantillas' }"
    >
      <!--primera columna-->
      <a-table-column
        key="order"
        title="N°"
        data-index="order"
        :width="60"
        fixed="left"
      >
        <template slot-scope="order">
          <span>{{ order }}</span>
        </template>
      </a-table-column>
      <a-table-column
        key="name"
        title="Nombre de plantilla"
        data-index="name"
        :width="200"
      >
        <span slot-scope="name">{{ name }}</span>
      </a-table-column>
      <!--segunda columna-->
      <a-table-column
        key="friendlyName"
        title="Nombre de visualización"
        data-index="friendlyName"
        :width="200"
      >
        <template slot-scope="friendlyName, record">
          <span>
            <i v-if="record.sandbox">{{ friendlyName }} (sandbox) </i>
            <span v-else class="display-flex align--center">
              <span class="ellipsis">
                {{ friendlyName }}
              </span>
              <a-icon
                v-if="iconStatus(record.tracking).show"
                :type="iconStatus(record.tracking).icon"
                :class="`table__icon--${
                  record.tracking[record.tracking.length - 1].status
                }`"
              />
            </span>
          </span>
        </template>
      </a-table-column>
      <!--tercera columna-->
      <a-table-column
        key="category"
        title="Categoria"
        data-index="category"
        :ellipsis="true"
        :width="150"
      >
        <template slot-scope="category">
          <span>{{ category }}</span>
        </template>
      </a-table-column>
      <!--cuarta columna-->
      <a-table-column
        key="typeAttachment"
        title="Tipo de plantilla"
        data-index="typeAttachment"
        :ellipsis="true"
        :width="150"
      >
        <template slot-scope="typeAttachment">
          <span>{{ transformTypeAttachment(typeAttachment) }}</span>
        </template>
      </a-table-column>
      <!-- columna de botones-->
      <a-table-column
        key="buttons"
        title="Opción de botones"
        data-index="buttons"
        :ellipsis="true"
        :width="150"
      >
        <template slot-scope="buttons">
          <span>{{
            buttons ? `Con ${buttons.length} botones` : 'Sin botones'
          }}</span>
        </template>
      </a-table-column>
      <!--quinta columna-->
      <a-table-column
        key="tracking"
        title="Estado"
        data-index="tracking"
        :width="100"
      >
        <template slot-scope="tracking">
          <a-tag
            v-if="TEMPLATES_STATUSES[tracking[tracking.length - 1].status]"
            :color="
              TEMPLATES_STATUSES[tracking[tracking.length - 1].status].color
            "
            >{{
              TEMPLATES_STATUSES[tracking[tracking.length - 1].status].title
            }}</a-tag
          >
        </template>
      </a-table-column>
      <!--sexta columna-->
      <a-table-column
        v-if="showAllColumns"
        key="active"
        title="Activo"
        data-index="active"
        :width="80"
      >
        <template slot-scope="active, record">
          <template v-if="disabledCell(record.tracking)">
            <span>-</span>
          </template>
          <template v-else-if="active">
            <a-switch
              size="small"
              default-checked
              @change="changeState(record)"
            />
          </template>
          <template v-else>
            <a-switch size="small" @change="changeState(record)" />
          </template>
        </template>
      </a-table-column>
      <!--setima columna-->
      <a-table-column
        v-if="showAllColumns"
        key="lines"
        title="Colas asignadas"
        data-index="lines"
        :width="140"
      >
        <template slot-scope="lines, record"
          >{{ disabledCell(record.tracking) ? '-' : lines.length }}
        </template>
      </a-table-column>
      <!--octava columna-->
      <a-table-column
        v-if="showAllColumns"
        key="users"
        title="Agentes asignados"
        data-index="users"
        :width="140"
      >
        <template slot-scope="users, record"
          >{{ disabledCell(record.tracking) ? '-' : users.length }}
        </template>
      </a-table-column>
      <!--novena columna acciones-->
      <a-table-column key="action" title="Acciones" fixed="right" :width="180">
        <template slot-scope="text, record">
          <span v-if="!disabledCell(record.tracking)">
            <a @click="handleEdit(record)">Editar</a>
            <a-divider type="vertical" />
            <a @click="handleAssign(record)">Asignar</a>
          </span>
          <span v-else>
            <a @click="handleViewDetails(record)">Ver detalles</a>
          </span>
        </template>
      </a-table-column>
    </a-table>
    <!--MODALES-->
    <a-modal
      v-model="visibleDetails"
      title="Detalles de la plantilla"
      :footer="null"
    >
      <template v-for="(item, index) in details">
        <template v-if="item.name !== 'Parámetros'">
          <div class="item" :key="index">
            <h6 class="heading-h7 label" style="margin-bottom: 4px">
              {{ item.name }}
            </h6>
            <p class="body-2 value">
              {{ item.value }}
            </p>
          </div>
        </template>
        <template v-else>
          <div :key="index" v-if="item.value.length">
            <h6 class="heading-h6">Parámetros</h6>
            <div v-for="(param, index) in item.value" :key="`param${index}`">
              <h6 class="heading-h7">{{ `Parámetro ${index + 1}` }}</h6>
              <p class="body-1">{{ param.title }}</p>
            </div>
          </div>
        </template>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import attemptMixin from '@/app/shared/mixins/attempt'
import {
  TYPE_TEMPLATES_FOR_LIST,
  TEMPLATES_STATUSES,
} from '@/app/channels/utils/componentsDataTemplates'
import moment from 'moment'
moment.locale('es')

export default {
  name: 'TemplatesList',
  props: {
    dataTemplates: {
      type: Object,
      required: true,
    },
    load: {
      type: Boolean,
      required: false,
    },
    requestState: {
      type: String,
      required: false,
    },
  },
  mixins: [attemptMixin],

  data: () => ({
    TEMPLATES_STATUSES,
    loadLocal: false,
    details: [],
    visibleDetails: false,
    lastChild: null,
    observer: null,
    morePaginate: true,
    isLoading: false,
    moment: moment,
  }),
  mounted() {
    this.setLastChild()
  },
  computed: {
    /**
     * Mostrar todas las columnas
     * @returns {Boolean}
     */
    showAllColumns() {
      return this.requestState == 'approved' || this.requestState == 'all'
    },
  },
  methods: {
    ...mapActions(['changeTempateState']),
    handleEdit(record) {
      this.$emit('handleEdit', record)
    },
    handleAssign(record) {
      this.$emit('handleAssign', record)
    },
    async changeState(record) {
      const newSatus = !record.active
      const result = await this.changeTempateState({
        templateId: record._id,
      })
      if (result.success) {
        if (newSatus === false) {
          this.$message.warning({
            content: `Recuerda que esta plantilla ya no podrá ser usada por los agentes`,
            duration: 5,
          })
        }
        this.$message.success({
          content: `Se cambió el estado de la plantilla exitosamente`,
          duration: 3,
        })
        this.$emit('onEditState')
      } else {
        this.$message.error({
          content: `Ocurrió un error al actualizar el estado`,
          duration: 2,
        })
      }
    },
    /**
     * Muestra los detalles de cada plantilla
     * @param {Object} record.name
     * @param {String} record.friendlyName
     * @param {String} record.category
     * @param {String} record.lang
     * @param {String} record.typeAttachment
     * @param {Object[]} record.tracking
     * @param {String} record.tracking[].status
     * @param {String} record.body
     * @param {String[]} record.parameters
     */
    handleViewDetails(record) {
      this.visibleDetails = true

      this.details = [
        { name: 'Nombre de plantilla', value: record.name },
        { name: 'Nombre de visualización', value: record.friendlyName },
        { name: 'Categoría', value: record.category },
        {
          name: 'Lenguaje',
          value: record.lang === 'es' ? 'Español' : 'Inglés',
        },
        {
          name: 'Tipo de plantilla',
          value: this.transformTypeAttachment(record.typeAttachment),
        },
        {
          name: 'Estado de plantilla',
          value: this.transformState(
            record.tracking[record.tracking.length - 1].status
          ),
        },
        {
          name: 'Última fecha de estado',
          value: moment(
            record.tracking[record.tracking.length - 1].created_at
          ).format('LLL'),
        },
        { name: 'Contenido', value: record.body },
        { name: 'Parámetros', value: record.parameters },
      ]
    },
    setLastChild() {
      this.intervalAttempt(() => {
        const childs = document.getElementsByClassName('ant-table-row')
        this.lastChild = childs[childs.length - 1]
        this.observer = new IntersectionObserver(this.handleBottomReached, {
          threshold: 0.5, // 0.5 para secciones pequeñas y 1.0 para secciones grandes
        })
        this.observer.observe(this.lastChild)
      })
    },
    onPaginate() {
      this.morePaginate = true
    },
    offPaginate() {
      this.morePaginate = false
    },
    handleBottomReached(entries) {
      const entry = entries[0]
      if (entry.isIntersecting && this.morePaginate && !this.isLoading) {
        this.$emit('onBottomReached')
        this.isLoading = true
      }
    },
    /**
     * Transforma el estado de Estado
     * @param {String} state
     */
    transformState(state) {
      return this.TEMPLATES_STATUSES[state].title
    },
    /**
     * Transforma el estado a un icon
     * @param {String} state
     * @return {String} icon
     */
    transformIcon(state) {
      switch (state) {
        case 'approved':
          return 'check-circle'
        case 'pending':
          return ''
        case 'rejected':
          return 'close-circle'
      }
    },
    /**
     * Transforma el valor de la propiedad "typeAttachment"
     * @param {String} typeAttachment
     * @return {String}
     */
    transformTypeAttachment(typeAttachment) {
      const typeTemplate = TYPE_TEMPLATES_FOR_LIST.find(
        (typeTemplateForList) =>
          typeTemplateForList.value === (typeAttachment || 'TEXT')
      )
      return typeTemplate.title
    },
    /**
     * Deshabilitar la celda
     * @param {Object[]} tracking
     * @returns {Boolean}
     */
    disabledCell(tracking) {
      return tracking[tracking.length - 1]?.status !== 'approved'
    },
    /**
     * Obtiene el icono de acuerdo al estado
     * @param {Object[]} tracking
     * @param {String} tracking.status
     * @param {Date} tracking.created_at
     */
    iconStatus(tracking) {
      if (!tracking) return { show: false }
      const lastStatus = tracking[tracking.length - 1]
      const currentDate = new Date()
      return {
        show:
          lastStatus.status !== 'pending' &&
          moment(lastStatus.created_at).isSame(currentDate, 'day'),
        icon: this.transformIcon(lastStatus.status),
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.item
  margin-bottom: 8px
  .label
    color: $gray_dark_900
  .value
    color: $gray_dark_800
.body
  &-2
    margin-bottom: 0px
    white-space: pre-wrap
    word-wrap: break-word
    font-family: inherit
.table__icon--approved
  color: $polar_green_6
  font-size: 16px
  margin-left: 12px
.table__icon--rejected
  color: $red_6
  font-size: 16px
  margin-left: 12px
</style>
